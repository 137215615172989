:root {
    --tt-comment--color: #f4f4f4;
    --tt-comment--text-color: #000;
    --tt-comment--color--current-user: rgba(var(--tt-secondary-color-rgb), 0.8);
    --tt-comment--text-color--current-user: var(--tt-secondary-text-color);
}

:root.dark {
    --tt-comment--color: #333;
    --tt-comment--text-color: #f4f4f4;
    --tt-comment--color--current-user: rgba(var(--tt-secondary-color-rgb), 0.8);
    --tt-comment--text-color--current-user: var(--tt-secondary-text-color);
}

.tt-comment {
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
}

.tt-comment--current-user {
    flex-direction: row;
}

.tt-comment__article {
    display: flex;
    flex-direction: column;
    width: fit-content;
    align-self: flex-start;
}

.tt-comment--current-user .tt-comment__article {
    align-content: flex-end;
    align-items: flex-end;
}

.tt-comment--current-user .tt-input__label {
    align-self: flex-start;
}

.tt-comment__actions {
    display: flex;
    align-items: center;
}

.tt-comment__content {
    background: var(--tt-comment--color);
    color: var(--tt-comment--text-color);
    padding: 0.8rem 1.6rem;
    border-radius: 0.8rem 0.8rem 0.8rem 0;
    max-width: fit-content;
    overflow: hidden;
    font-size: var(--tt-font-size);
}

.tt-comment__content img {
    max-width: calc(100vw - 12rem) !important;
}

.tt-comment--current-user .tt-comment__content {
    background: rgba(var(--tt-secondary-color-rgb), 0.8);
    background: var(--tt-comment--color--current-user);
    color: var(--tt-comment--text-color--current-user);
    border-radius: 0.8rem 0.8rem 0 0.8rem;
}

.tt-comment--current-user .tt-comment__content a {
    color: var(--tt-comment--text-color--current-user);
    text-decoration: underline;
}
