:root:not(.kendo-material-enabled) {
    & .k-alt,
    & .k-pivot-layout > tbody > tr:first-child > td:first-child,
    & .k-resource.k-alt,
    & .k-separator {
        background-color: var(--tt-alt-color);
    }

    & .k-event {
        background-color: var(--tt-primary-color);
    }

    & .k-event-template {
        padding: 0.3em 1.4em 0.3em 0.3em;
        height: inherit;
    }

    & .k-loading-mask {
        min-height: 50px;
    }

    & .k-draghandle.k-state-selected:hover,
    & .k-ghost-splitbar-horizontal,
    & .k-ghost-splitbar-vertical,
    & .k-list > .k-state-highlight,
    & .k-list > .k-state-selected,
    & .k-marquee-color,
    & .k-panel > .k-state-selected,
    & .k-scheduler .k-scheduler-toolbar .k-state-selected,
    & .k-scheduler .k-today.k-state-selected,
    & .k-state-selected,
    & .k-state-selected.k-state-hover,
    & .k-state-selected:link,
    & .k-state-selected:visited,
    & .k-tool.k-state-selected {
        color: #fff;
        background-color: #1984c8;
        border-color: #1a87cd;
        background: var(--tt-primary-color);
        border-color: var(--tt-primary-color);
    }

    & .k-scheduler .k-scheduler-toolbar .k-state-default.k-state-hover,
    & .k-treeview .k-in.k.k-state-hover {
        background: rgba(var(--tt-primary-color-rgb), 0.3);
        border-color: var(--tt-primary-color);
    }

    & .k-scheduler .k-scheduler-toolbar .k-state-default.k-state-selected,
    & .k-grid tr.k-state-selected:hover {
        background: var(--tt-primary-color);
        border-color: var(--tt-primary-color);
    }

    & .k-link {
        font-weight: 700;
    }

    & .k-button,
    & .k-grid .k-header .k-button {
        background-color: transparent;
    }

    & .k-button:hover,
    & .k-grid .k-header .k-button:hover {
        background-color: rgba(var(--tt-primary-color-rgb), 0.4);
    }

    & .k-scheduler-layout {
        background-color: var(--tt-card--color);
    }
}
.kendo-material-enabled:root {
    --kendo-font-size: var(--tt-font-size);
    --kendo-font-size-xs: var(--tt-font-size-xs);
    --kendo-font-size-sm: var(--tt-font-size-sm);
    --kendo-font-size-md: var(--tt-font-size);
    --kendo-font-size-lg: var(--tt-font-size-lg);
    --kendo-font-size-xl: var(--tt-font-size-xl);
    --kendo-icon-size: var(--tt-font-size-lg);
    --kendo-spacing-1\.5: 0.475rem;
    --kendo-font-family: "Open Sans", sans-serif;
    --kendo-font-family-sans-serif: "Open Sans", sans-serif;

    & .k-svg-icon {
        height: var(--tt-font-size-lg);
        width: var(--tt-font-size-lg);
    }
}
